<template>
  <div class="useral-edit-user" :class="[{'vx-card main-box p-2': $route.name === 'editUser'}]">
    <div class="edit-user-fields">
      <custom-profile-image-input v-model="user.image"/>
      <template v-if="checkUserPermissions(['role.show', 'user.set_role']) === 2">
        <u-radio-input
            id="roleRadio"
            :label="$t('users.user.labels.role')"
            class="mt-2"
            :options="getAvailableRoles"
            v-model="user.role"
        />
      </template>

      <u-radio-input
          v-if="checkUserPermissions(['character.show', 'user.set_character']) === 2"
          id="characterRadio"
          :label="$t('users.user.labels.character')"
          class="mt-2"
          :options="characters"
          v-model="user.character"
      />

      <u-radio-input
          id="genderRadio"
          :label="$t('users.user.labels.gender')"
          class="mt-2"
          :options="gender"
          v-model="user.gender"
      />

      <custom-validate-input :label="$t('users.user.labels.name')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('users.user.validators.name')"
                             :regex="nameRegex"
                             v-model="user.name"/>

      <custom-validate-input :label="$t('users.user.labels.family')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('users.user.validators.family')"
                             :regex="nameRegex"
                             v-model="user.lastName"/>

      <u-phone-input v-model="user.phoneNumber"
                     :label="$t('users.user.labels.phoneNumber')"
                     auto-focus
                     :default-country-code="clubInfo.club_default_country_code"
      />

      <vs-button id="deleteUserBTN"
                 class="w-full mt-3"
                 color="danger"
                 v-if="checkUserPermissions('user.delete')"
                 @click="$refs.deleteConfirmation.showDialog()">
        {{ $t('users.user.labels.delete') }}
      </vs-button>

    </div>

    <custom-dialog ref="deleteConfirmation"
                   :title="$t('users.user.confirmations.delete.title')"
                   :body="$t('users.user.confirmations.delete.body', {name: `${user.name.value} ${user.lastName.value}`})"
                   @accept="deleteUser"/>

    <vs-button v-show="false"
               id="EditUserBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from "vuex";
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput"
  import CustomDialog from '../../../../components/customDialog/customDialog'
  import UPhoneInput from "../../../../components/customInput/UPhoneInput.vue";
  import {deleteUser, editUser, getUser} from '../../../../http/requests/users/users'
  import {getRoles} from '../../../../http/requests/settings/roles'
  import {getCharacters} from "../../../../http/requests/characters"
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import URadioInput from "@/components/customInput/URadioInput.vue";

  export default {
    name: 'editUser',
    components: {
      URadioInput,
      UPhoneInput,
      CustomProfileImageInput,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      userId: 0
    },
    data() {
      return {
        nameRegex: this.$validator('regex.user.name'),
        characters: [],
        gender: [
          {
            value: 1,
            label: this.$t('users.genderTypes.man')
          },
          {
            value: 2,
            label: this.$t('users.genderTypes.woman')
          }
        ],
        user: {
          name: {
            value: '',
            isValid: true
          },
          lastName: {
            value: '',
            isValid: true
          },
          image: {
            value: '',
            isValid: true
          },
          gender: {
            value: -1,
            label: this.$t('users.genderTypes.man')
          },
          phoneNumber: {
            value: '',
            isValid: true
          },
          character: {
            value: 1
          },
          role: {
            label: '-',
            value: 0
          },
          balance: 0,
          maxDebt: 0,
        },
        roles: [],
        actions: {
          toolbar: [
            {
              id: 'EditUserBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'editUser' ? 'user' : 'customers'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        clubInfo: 'setting/getClubInfo'
      }),
      getAvailableRoles () {
        const userId = this.$store.state.auth.user.role.id || 0
        if (userId === 1) {
          return this.roles
        } else {
          return this.roles.slice(1, this.roles.length)
        }
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      })
      this.$store.dispatch('setPageTitle', this.$t('users.user.edit.title'))

      this.getUser()
      if (checkUserPermissions('role.show')) {
        this.getAccessGroups()
      }
      if (checkUserPermissions('character.show')) {
        this.getCharacters()
      }
    },
    methods: {
      getUser() {
        getUser(this.userId || this.$route.params.id).then(response => {
          const user = response.data.data

          this.user.name.value = user.name || ''
          this.user.lastName.value = user.family || ''
          this.user.image.value = user.avatar || ''
          this.user.phoneNumber.value = user.phone_number || ''
          this.user.role = {
            label: user.role && user.role.name ? user.role.name : this.$t('users.user.labels.customer'),
            value: user.role && user.role.id ? user.role.id : 0
          }
          this.user.character.value = user.character && user.character.id ? user.character.id : 1
          this.user.gender = {
            value: user.gender > 0 ? user.gender : -1,
          }
          this.user.balance = user.balance
          this.user.maxDebt = user.max_debt > 0 ? user.max_debt : 0

        }).catch(() => {
        })
      },
      getCharacters() {
        getCharacters().then(response => {
          const characters = response.data.data
          characters.forEach(character => {
            this.characters.push({
              value: character.id,
              label: character.name
            })
          })
        })
      },
      getAccessGroups() {
        getRoles().then(response => {
          this.roles = this.getRoles(response.data.data)
          this.roles.push({
            label: this.$t('users.user.labels.none'),
            value: 0
          })
        })
      },
      getRoles(role) {
        let roles = [
          {
            label: role.name,
            value: role.id
          }
        ]

        if (role.children && role.children.length > 0) {
          role.children.forEach((child) => {
            roles = [...roles, ...this.getRoles(child)]
          })
        }
        return roles
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      deleteUser() {
        if (this.user.balance > 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('users.user.notifications.hasBalance'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        } else {
          deleteUser(this.userId || this.$route.params.id).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('users.user.notifications.delete.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })
            this.$router.push({name: 'customers'})
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('users.user.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        }
      },
      sendData() {
        if (this.user.gender.value === -1 ||
          !this.user.name.isValid ||
          !this.user.lastName.isValid ||
          !this.user.phoneNumber.isValid) {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('users.user.notifications.wrongValues'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const user = {
          name: this.user.name.value,
          family: this.user.lastName.value,
          avatar: this.user.image ? this.user.image.value : '',
          gender: this.user.gender.value,
          phone_number: this.user.phoneNumber.value,
          company: '',
          max_debt: this.user.maxDebt,
        }

        if (checkUserPermissions(['character.show', 'user.set_character']) === 2) user.character_id = this.user.character.value

        if (this.user.role && this.userId !== this.$store.state.auth.user.id) user.role_id = this.user.role.value || null

        editUser(this.userId || this.$route.params.id, user).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('users.user.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })

          this.$router.push({name: 'customers'})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            const error_mapper = {
              'name': this.$t('users.user.notifications.parseError.name'),
              'family': this.$t('users.user.notifications.parseError.family'),
              'national_code': this.$t('users.user.notifications.parseError.nationalCode'),
              'born_at': this.$t('users.user.notifications.parseError.birthDate'),
              'gender': this.$t('users.user.notifications.parseError.gender'),
              'phone_number': this.$t('users.user.notifications.parseError.phoneNumber'),
              'email': this.$t('users.user.notifications.parseError.email'),
              'character_id': this.$t('users.user.notifications.parseError.character'),
              'role_id': this.$t('users.user.notifications.parseError.role')
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('users.user.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    },
  }
</script>

<style lang="scss">
  .useral-edit-user {
    width: 100%;
    height: calc(100% - 10px);

    .edit-user-fields {
      max-width: 400px;

      .custom-profile-image-input {
        .profile-image-label {
          height: 110px;
          width: 110px;
        }
      }

      .date-picker-item {
        border: 0 !important;
        border-radius: 0.4rem;
        position: relative;

        .date-picker-label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 5px;
          padding: 0 5px;
          background-color: #ffffff;
          z-index: 1;
        }

        .vpd-input-group {
          width: 20px !important;
          position: absolute;
          top: 6px;
          left: 2px;

          .vpd-icon-btn {
            opacity: 1 !important;
            height: 25px !important;
            width: 20px !important;
            background-color: inherit !important;

            svg {
              fill: #000000;
              overflow: visible !important;
            }
          }

          .form-control {
            border: 0;
            width: 100%;
            display: none;
            line-height: 35px;
          }
        }
      }

      .input-field-item {
        position: relative;
        border: 1px solid #cdcdcd;
        border-radius: 0.4rem;
        padding: 5px 10px;
        margin: 15px 0;
        display: flex;
        justify-content: space-around;
        min-height: 35px;
        flex-wrap: wrap;

        &.character-input {
          justify-content: space-between;
        }

        &.invalid {
          border-color: #b00000;

          .input-field-label {
            color: #b00000;
          }
        }

        .input-field-label {
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 8px;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .radio-item {
          display: flex;
          align-items: center;
          width: max-content;
          direction: rtl;

          label {
            direction: ltr;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.character-input {
          justify-content: flex-start;

          .radio-item {
            justify-content: flex-end;
            width: 33%;
          }
        }
      }

      div.edit-user-field {
        position: relative;

        span.label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 7px;
          z-index: 10000;
          background: #ffffff;
          padding: 0 5px;
        }

        .useral-custom-element-select {
          margin: 15px 0;

          .selected {
            height: 35px;
            line-height: 35px;
            font-size: 13px;
          }

          .items {
            line-height: 35px;

            div {
              height: 35px;
              font-size: 13px;
            }
          }
        }
      }

      .error-alert {
        display: inline-block;
        border: 1px solid #ffba00;
        border-radius: .5rem;
        color: #ffba00;
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;

        i {
          font-size: 15px;
        }
      }

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 10px;
        background: #f9f9fd;
        right: 10px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }
    }
  }
</style>
